import "./App.css"
import { RecordingsScreen } from "./Recordings";
import { VideoScreen, AudioScreen } from "./Stream";
import "./Recordings.css"
import { useState } from "react";

import duckie from "./assets/duckie.png"
import sclogo from "./assets/soundcloud-logo.png"
import iglogo from "./assets/instagram-logo.png"
import bclogo from "./assets/bandcamp-logo.png"
import emlogo from "./assets/email-logo.png"
import { useDuckofthemonth, useSchedule } from "./providers/providers";

const App = () => {
  return (
    <div className="App">
      <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Monoton" />
      <div className="App-body">
        <AppHeader />
        <AppLogo />
        <AppContent />
      </div>
      <AppFooter />
    </div>
  );
}

const AppHeader = () => {
  const duck = useDuckofthemonth();
  const title = "[" + duck?.month + "] Duck of the Month: " + duck?.name + " (" + duck?.eng_name + ")"

  return (
    <div className="App-header">
      <p>{title}</p>
      <p>
        (
        <i>
          <a href={duck?.pic_url}>
            {duck?.latin_name}
          </a>
        </i>
        )
      </p>
    </div>
  )
}

const AppLogo = () => {
  return (
    <div className="App-logo">
      <div className="App-logo-container">
        <img height="100px" src={duckie} alt="fabulous duck" />
      </div>
      <text className="App-logo-title">DUCKSTAD RADIO</text>
    </div>
  )
}

enum Screen {
  Video = "Video",
  Audio = "Audio",
  Recordings = "Recordings",
  About = "About"
}


const AppContent = () => {

  const [currentScreen, setCurrentScreen] = useState(Screen.Video)
  const getViewer = () => {
    switch (currentScreen) {
      case Screen.Video: return <VideoScreen />;
      case Screen.Audio: return <AudioScreen />;
      case Screen.Recordings: return <RecordingsScreen />;
      case Screen.About: return <AboutScreen />;
    }
  }

  const buttonProps = { currentScreen: currentScreen, setScreen: setCurrentScreen }

  return (

    <div className="App-content">
      <div className="App-viewer-title">
        <ViewerTitleButton {...{ screen: Screen.Video, ...buttonProps }} />
        <ViewerTitleButton {...{ screen: Screen.Audio, ...buttonProps }} />
        <ViewerTitleButton {...{ screen: Screen.Recordings, ...buttonProps }} />
        <ViewerTitleButton {...{ screen: Screen.About, ...buttonProps }} />
      </div>
      <div className="App-viewer">
        {getViewer()}
      </div>
    </div>
  )
}

const ViewerTitleButton = (props: { screen: Screen, currentScreen: Screen, setScreen: any }) => {
  const className = props.screen === props.currentScreen ?
    "App-viewer-title-chosen-button" :
    "App-viewer-title-button"

  const bottomWidth = () => {
    switch (props.currentScreen) {
      case Screen.Video: return 61;
      case Screen.Audio: return 61;
      case Screen.Recordings: return 100;
      case Screen.About: return 63;
    }
  }

  return (
    <div
      className={className}
      onClick={() => props.setScreen(props.screen)}
    >{props.screen}
      {props.screen === props.currentScreen ?
        <div className="App-viewer-title-button-bottom" style={{ width: bottomWidth() }}></div> :
        null
      }
    </div>
  )
}



const AppFooter = () => {
  const schedule = useSchedule();

  return <div className="App-footer">
    <div className="App-footer-socials">
      <div className="App-footer-title">
        SOCIALS
      </div>
      <div className="App-footer-socials-inner">
        <SocialIcon
          img={sclogo}
          url="https://soundcloud.com/duckstadradio"
        />
        <SocialIcon
          img={iglogo}
          url="https://instagram.com/duckstadradio"
        />
        <SocialIcon
          img={bclogo}
          url="https://duckstadradio.bandcamp.com"
        />
        <SocialIcon
          img={emlogo}
          url="mailto:radioduckstad@gmail.com"
        />
      </div>
    </div>
    <div className="App-footer-schedule">
      <div className="App-footer-title">
        SCHEDULE
      </div>
      <div className="App-footer-schedule-inner">

        {schedule && schedule.length > 0 ? (
          <ul>
            ({schedule?.map((item) => {
              return <li>{item.day}, {item.date}</li>
            })
            })
          </ul>
        ) : <b> TBA </b>
        }
      </div>
    </div>
  </div >
}

const SocialIcon = (props: { img: string, url: string }) => {
  return <a className="logo_box" href={props.url}>
    <img alt="logo-img" width="40px" height="40px" src={props.img} />
  </a>
}

const AboutScreen = () => {
  return (<div className="About-screen">
    <p className="About-text">
      Duckstad Radio is a selfmade underground radio station and tape label, dedicated to the rich history of weird music, as well as cutting-edge electronic developments. We tap into a definite but nameless current of music, ranging from dub to industrial and from jakbeat to p-funk, which has always been dedicated to revolutionizing music and society at large.
    </p>
    <br></br>
    <p className="About-text">
      Contact us at: <a href="mailto:radioduckstad@gmail.com"><b>radioduckstad@gmail.com</b></a>
    </p>
  </div>
  )
}

export default App;
